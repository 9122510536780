var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warehousePosition" },
    [
      _c("div", { staticClass: "pvhFactory" }, [
        _c(
          "div",
          {},
          [
            _c("div", { staticClass: "layTop d-flex" }, [
              _c(
                "div",
                {
                  staticClass: "Title",
                  staticStyle: { "margin-bottom": "10px" }
                },
                [_vm._v(_vm._s(_vm.$t("Warehouse Position")))]
              )
            ]),
            _c(
              "el-row",
              { staticClass: "topFromSearch", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { height: "auto" },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                      },
                      [
                        _c(
                          "span",
                          {
                            style:
                              _vm.codeVal == ""
                                ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                : "top: -10px;left: 20px;transform: translate(0, 0);"
                          },
                          [_vm._v(_vm._s(_vm.$t("KEYWORDS")) + ":")]
                        ),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.codeVal,
                            callback: function($$v) {
                              _vm.codeVal = $$v
                            },
                            expression: "codeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                      },
                      [
                        _c(
                          "span",
                          {
                            style:
                              _vm.typeVal == ""
                                ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                : "top: -10px;left: 20px;transform: translate(0, 0);"
                          },
                          [_vm._v(_vm._s(_vm.$t("TYPE")) + ":")]
                        ),
                        _c("el-input", {
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.typeVal,
                            callback: function($$v) {
                              _vm.typeVal = $$v
                            },
                            expression: "typeVal"
                          }
                        })
                      ],
                      1
                    ),
                    _c("el-col", {
                      staticClass: "formDiv",
                      staticStyle: { display: "none" },
                      attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                    }),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 4, xl: 4 }
                      },
                      [
                        _c("span", { attrs: { id: "venSpan" } }, [
                          _vm._v(_vm._s(_vm.$t("Warehouse")) + ":")
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: ""
                            },
                            on: {
                              focus: function($event) {
                                return _vm.focusDiv($event)
                              },
                              blur: function($event) {
                                return _vm.blurDiv($event)
                              }
                            },
                            model: {
                              value: _vm.warehouseVal,
                              callback: function($$v) {
                                _vm.warehouseVal = $$v
                              },
                              expression: "warehouseVal"
                            }
                          },
                          _vm._l(_vm.GetCurrWarehouseData, function(item) {
                            return _c("el-option", {
                              key: item.warehouseCode,
                              attrs: {
                                label: item.warehouseName,
                                value: item.warehouseCode
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "topFromSearch", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "align-self": "flex-end"
                    },
                    attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          title: _vm.$t("Search"),
                          icon: "iconfont sousuo",
                          size: "mini",
                          type: "primary"
                        },
                        on: { click: _vm.search }
                      },
                      [_vm._v("  " + _vm._s(_vm.$t("Search")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "iconfont tianjia",
                          size: "mini",
                          title: _vm.$t("New"),
                          plain: ""
                        },
                        on: { click: _vm.addBtn }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("New")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", {
              staticStyle: {
                "padding-top": "10px",
                "text-align": "right",
                "align-self": "flex-end"
              }
            }),
            _c(
              "div",
              { staticStyle: { "margin-top": "10px" } },
              [
                _c(
                  "avue-crud",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      }
                    ],
                    staticClass: "pvhTable",
                    attrs: {
                      option: _vm.tableOpt,
                      data: _vm.tableData,
                      "row-style": _vm.rowStyle,
                      page: _vm.page
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "current-change": _vm.currentChange
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "menu",
                        fn: function(ref) {
                          var row = ref.row
                          var index = ref.index
                          var size = ref.size
                          var type = ref.type
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.editBtn(row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Edit")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.delBtn(row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Delete")))]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.obj,
                      callback: function($$v) {
                        _vm.obj = $$v
                      },
                      expression: "obj"
                    }
                  },
                  [
                    _c(
                      "template",
                      {
                        staticStyle: { width: "calc(100% - 80px)" },
                        slot: "menuRight"
                      },
                      [
                        _c(
                          "el-row",
                          {
                            staticClass: "topFromSearch",
                            attrs: { gutter: 20 }
                          },
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { "padding-top": "10px" },
                                attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      type: "primary",
                                      size: "small",
                                      icon: "el-icon-plus"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                  " +
                                        _vm._s(_vm.$t("ADD")) +
                                        "\n                              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Warehouse Position"),
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "none" },
                      attrs: { label: _vm.$t("PositionCode"), prop: "id" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.Id,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "Id", $$v)
                          },
                          expression: "ruleForm.Id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("Warehouse"),
                        prop: "warehouseCode"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "" },
                          on: {
                            change: function($event) {
                              return _vm.warehousechange(
                                _vm.ruleForm.warehouseCode
                              )
                            }
                          },
                          model: {
                            value: _vm.ruleForm.warehouseCode,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "warehouseCode", $$v)
                            },
                            expression: "ruleForm.warehouseCode"
                          }
                        },
                        _vm._l(_vm.GetCurrWarehouseData, function(v, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: v.warehouseName,
                              value: v.warehouseCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("ParentPositionCode"),
                        prop: "parentPositionCode"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.ruleForm.parentPositionCode,
                            callback: function($$v) {
                              _vm.$set(_vm.ruleForm, "parentPositionCode", $$v)
                            },
                            expression: "ruleForm.parentPositionCode"
                          }
                        },
                        _vm._l(_vm.GetParentPositionCodeData, function(
                          v,
                          index
                        ) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: v.positionCode,
                              value: v.positionCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("PositionCode"),
                        prop: "positionCode"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.positionCode,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "positionCode", $$v)
                          },
                          expression: "ruleForm.positionCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("PositionType"),
                        prop: "positionType"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.positionType,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "positionType", $$v)
                          },
                          expression: "ruleForm.positionType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Length"), prop: "length" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.length,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "length", $$v)
                          },
                          expression: "ruleForm.length"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Width"), prop: "width" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.width,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "width", $$v)
                          },
                          expression: "ruleForm.width"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Height"), prop: "height" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.height,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "height", $$v)
                          },
                          expression: "ruleForm.height"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("OK")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }