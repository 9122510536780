<template>
  <div class="warehousePosition">
    <div class="pvhFactory">
        <div class="">
            <div class="layTop d-flex">
                <div class="Title" style="margin-bottom: 10px;">{{ $t('Warehouse Position') }}</div>

            </div>
            <el-row :gutter="20" class="topFromSearch" >
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span :style="codeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('KEYWORDS')}}:</span>
                        <el-input @focus="focusDiv($event)" @blur="blurDiv($event)" v-model="codeVal"></el-input>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span :style="typeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('TYPE')}}:</span>
                        <el-input v-model="typeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4" style="display:none">
                        
                    </el-col>
                    <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                        <span id="venSpan" :style="">{{$t('Warehouse')}}:</span>
                        <el-select v-model="warehouseVal" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;">
                            <el-option v-for="item in GetCurrWarehouseData"
                                       :key="item.warehouseCode"
                                       :label="item.warehouseName"
                                       :value="item.warehouseCode">
                            </el-option>
                        </el-select>
                    </el-col>
                  
                </el-col>

            </el-row>
            <el-row :gutter="20" class="topFromSearch">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: right; align-self: flex-end;">
                    <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="search">  {{$t('Search')}}</el-button>
                    <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="addBtn"> {{$t('New')}}</el-button>
                </el-col>
            </el-row>
            <div style="padding-top: 10px; text-align: right; align-self: flex-end;">
               
            </div>
            <div style="margin-top: 10px;">
                <avue-crud class="pvhTable"
                           v-loading="loading"
                           v-model="obj"
                           :option="tableOpt"
                           :data="tableData"
                           :row-style="rowStyle"
                           :page.sync="page"
                           @current-change="currentChange">

                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">

                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px;">
                                <el-button type="primary" size="small" icon="el-icon-plus" style="margin-left:10px;">
                                    {{ $t('ADD') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button type="text" size="small" @click="editBtn(row)">{{ $t('Edit') }}</el-button>
                        <el-button type="text" size="small" @click="delBtn(row)">{{ $t('Delete') }}</el-button>
                    </template>

                </avue-crud>
            </div>
        </div>
    </div>

    <el-dialog
        :title="$t('Warehouse Position')"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose">
      <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
              <el-form-item :label="$t('PositionCode')" prop="id" style="display:none">
                  <el-input v-model="ruleForm.Id"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Warehouse')" prop="warehouseCode">
                  <el-select v-model="ruleForm.warehouseCode" placeholder="" style="width: 100%;"  @change="warehousechange(ruleForm.warehouseCode)">
                      <el-option v-for="(v, index) in GetCurrWarehouseData" :label="v.warehouseName" :value="v.warehouseCode"
                                 :key="index"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item :label="$t('ParentPositionCode')" prop="parentPositionCode">
                  <el-select v-model="ruleForm.parentPositionCode" placeholder="" style="width: 100%;">
                      <el-option v-for="(v, index) in GetParentPositionCodeData" :label="v.positionCode" :value="v.positionCode"
                                 :key="index"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item :label="$t('PositionCode')" prop="positionCode">
                  <el-input v-model="ruleForm.positionCode"></el-input>
              </el-form-item>
              <el-form-item :label="$t('PositionType')" prop="positionType">
                  <el-input v-model="ruleForm.positionType"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Length')" prop="length">
                  <el-input v-model="ruleForm.length"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Width')" prop="width">
                  <el-input v-model="ruleForm.width"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Height')" prop="height">
                  <el-input v-model="ruleForm.height"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">OK</el-button>
              </el-form-item>
          </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
    import {
        GetWarehousePositions,
        GetWarehousePosition,
        PostWarehousePosition,
        PutWarehousePosition,
        DeleteWarehousePosition,
        GetListWarehouseInfos,
        GetListWarehousePositionsByWarehouseCode,
        GetParentListWarehousePositionsByWarehouseCode
    } from '@/api/warehouse'

export default {
  name: "warehousePosition",
  data() {
    return {
      loading: false,
      option: [],
        GetCurrWarehouseData: [],
        GetParentPositionCodeData:[],
      ruleForm: {
        id: false,
        warehouseCode: '',
        positionCode: '',
        positionType: '',
        corporationId: '',
        length: '',
        width: '',
        height:'',
        remark: ''
      },
      rules: {
          warehouseCode: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
          positionCode: [
          {required: true, message: this.$t('Please enter'), trigger: 'blur'},
        ],
      },
        dialogVisible: false,
        codeVal: '',
        typeVal: '',
        warehouseVal:'',
      YesNoData: [
        {
          label: this.$t('Disable'),
          value: 1
        }, {
          label: this.$t('Available'),
          value: 0
        }

      ],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev, next",
        background: true,
        pageSize: 10
      },
      tableData: [],
      obj: {},
    }
  },
  created() {
    this.list();
      GetListWarehouseInfos().then((response) => {
          this.GetCurrWarehouseData = response.data;
      });
      GetListWarehousePositionsByWarehouseCode(this.ruleForm.warehouseCode).then((response) => {
          this.GetParentPositionCodeData = response.data;
      }); 
  },
  methods: {
    submitForm(formName) {
      var ruleForm = this.ruleForm;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          if (ruleForm.id) {
            PutWarehousePosition({
                warehouseCode: ruleForm.warehouseCode,
                parentPositionCode: ruleForm.parentPositionCode,
                positionCode: ruleForm.positionCode,
                positionType: ruleForm.positionType,
                length: ruleForm.length,
                width: ruleForm.width,
                height: ruleForm.height,
                id: ruleForm.Id
            }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          } else {
            PostWarehousePosition({
                warehouseCode: ruleForm.warehouseCode,
                parentPositionCode: ruleForm.parentPositionCode,
                positionCode: ruleForm.positionCode,
                positionType: ruleForm.positionType,
                length: ruleForm.length,
                width: ruleForm.width,
                height: ruleForm.height,
                docStatus: 0
            }).then((response) => {
              if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({message: this.$t('OperationSuccess'), type: "success"});
                this.page.total = 0;
                this.list();
              } else {
                this.$message({message: this.$t('OperationFailed'), type: "warning"});
              }
              this.resetForm('ruleForm')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.ruleForm = {
          id: '',
          warehouseCode: '',
          positionCode: '',
          positionType: '',
          length: '',
          width: '',
          height: ''
      }
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    search() {
        this.list();
      },
    delBtn(row) {
        DeleteWarehousePosition(row.id).then((response) => {
            if (response.data.statusCode == 200 && !response.data.isError) {
                this.$message({ message: this.$t('OperationSuccess'), type: "success" });
                this.page.total = 0;
                this.list();
            } else {
                this.$message({ message: this.$t('OperationFailed'), type: "warning" });
            }
      })
    },
    editBtn(row) {
      var ruleForm = this.ruleForm;
      this.dialogVisible = true;
      this.ruleForm.id = true;
        ruleForm.warehouseCode = row.warehouseCode;
        ruleForm.parentPositionCode = row.parentPositionCode;
        ruleForm.positionCode = row.positionCode;
        ruleForm.positionType = row.positionType;
        ruleForm.length = row.length;
        ruleForm.width = row.width;
        ruleForm.height = row.height;
        ruleForm.Id = row.id;
        GetParentListWarehousePositionsByWarehouseCode(this.ruleForm.warehouseCode, this.ruleForm.positionCode).then((response) => {
            this.GetParentPositionCodeData = response.data;
        });
    },
    list() {
        this.loading = true;
        GetWarehousePositions(this.codeVal, this.typeVal, this.warehouseVal, this.page.currentPage, this.page.pageSize).then((response) => {
        this.loading = false;
        this.tableData = response.data.warehousePositions;
        this.page.total = response.data.totalCount;
        this.page.pageSize = response.data.pageSize;
      })
    },
    addBtn() {
      this.dialogVisible = true;
      this.ruleForm.id = false;
      this.GetListWarehouseInfos();
     
      },
    warehousechange(value) {
          GetListWarehousePositionsByWarehouseCode(this.ruleForm.warehouseCode).then((response) => {
              this.GetParentPositionCodeData = response.data;
          });
      },
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
      },
      focusDiv(obj) {//�������ȡ����
          if (obj.currentTarget === undefined) {
              obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
          } else {
              if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                  obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
              } else {
                  obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
              }
          }
      },//������ʧȥ���� 
      blurDiv(obj) {
          if (obj.currentTarget === undefined) {
              if (obj.value === "") {
                  obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
              }
          } else if (obj.currentTarget === null) {
              if (this.fromVal.ids.length === 0) {
                  document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266;";
              }
          } else {
              if (obj.target.value == "") {
                  obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
              }
          }
      },
    currentChange(val) {
      this.page.currentPage = val
      this.list()
      // this.getOperateLogList();
    },
    handleClose() {
      this.resetForm('ruleForm');
      this.dialogVisible = false;
    }
  },
  computed: {
    tableOpt() {
      return {
        page: true,
        addBtn: true,
        refreshBtn: false,
        emptyText: this.$t('No Data'),
        menu: true,
        menuTitle: this.$t('OPERATION'),
        menuWidth: 300,
        editBtn: false,
        editBtnText: this.$t('Edit'),
        editTitle: this.$t('Edit'),
        addTitle: this.$t('ADD'),
        delBtn: false,
        align: 'center',
        header: false,
        simplePage: false,
        labelWidth: "33%",
        expand: false,
        rowKey: 'Id',
        expandRowKeys: [],
        ListByCorpData: [],
        column: [
          {
            label: this.$t('Warehouse Name'),
            prop: 'warehouseCode',
          },
          {
            label: this.$t('Code'),
            prop: 'positionCode',
          },
          {
              label: this.$t('TYPE'),
              prop: 'positionType',
          },
          {
              label: this.$t('Length'),
              prop: 'length',
          },
          {
              label: this.$t('Width'),
              prop: 'width',
          },
          {
              label: this.$t('Height'),
              prop: 'height',
          }
        ]
      }
    },
  }
}
</script>

<style scoped lang="scss">
    .warehousePosition {
        padding: 15px;

        .pvhFactory {
            padding: 15px;
            border-radius: 8px;
            background: #FFFFFF;
        }
    }

.layTop {
  justify-content: space-between;
}

.d-flex {
  display: flex;
}
</style>
